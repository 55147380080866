<template>
    <div>
        <div class="vx-row">
            <AllEmailsCard :data="emails"></AllEmailsCard>
        </div>
    </div>
</template>
]

<script>
import { http } from '@/services'

import AllEmailsCard from './Cards/AllEmails'

export default {
    name: 'Emails',
    props: [],
    components: {
        AllEmailsCard,
    },

    mounted() {
        this.emailsFetch()
    },

    data() {
        return {
            emails: null,
        }
    },
    methods: {
        emailsFetch() {
            http.get('emails_site')
                .then((response) => (this.emails = response.data))
                .catch((error) => console.log(error))
        },
    },
}
</script>
